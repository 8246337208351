import { Stack, Typography } from "trends/fabrics";

const AtlasDisclaimer = () => (
  <Stack gap={1}>
    <Typography variant="body2" color="text.secondary">
      Startup currently in product stealth mode working on a web system to
      visually organize information.
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Mizony, Inc a Delaware C-Corp, July 2024 Registered.
    </Typography>
  </Stack>
);

export default AtlasDisclaimer;
