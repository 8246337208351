import {
  Typography,
  AppBar,
  Toolbar,
  Box,
  styled,
  Container,
} from "trends/fabrics";
import ThemeProvider from "firms/theme/ThemeProvider";
import AtlasDisclaimer from "nodes/AtlasDisclaimer";
import AtlasAvatar from "nodes/AtlasAvatar";
import AtlasGrowGlobe from "nodes/AtlasGrowGlobe";

const BottomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  top: "auto",
  bottom: 0,
}));

const Atlas = () => (
  <ThemeProvider>
    <Container sx={{ my: 2 }} maxWidth="xs">
      <AtlasAvatar />
      <Box py={2} />
      <AtlasDisclaimer />
      <AtlasGrowGlobe />
      <BottomAppBar position="fixed">
        <Toolbar sx={{ justifyContent: "center" }}>
          <Typography variant="caption" color="text.secondary">
            https://mizony.com
          </Typography>
        </Toolbar>
      </BottomAppBar>
    </Container>
  </ThemeProvider>
);

export default Atlas;
